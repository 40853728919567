
.mulle-element {
    position: absolute;
    top: 0;
    left: 0;
}

.mulle-element-nav {
    @extend .mulle-element;
    width: $nav-logo-width;
    height: $nav-logo-height;
}

.mulle-element-welcome {
    @extend .mulle-element;
    width: $welcome-logo-width;
    height: $welcome-logo-height;
}


@keyframes spin {
	from { -webkit-transform: rotate(0); }
	to   { -webkit-transform: rotate(360deg); }
}

#mulle-ring {
    z-index: 2;
	animation: spin 20s infinite linear;
    background: radial-gradient(rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0.0) 60%);
}

@keyframes mullerich {
	0%, 100% { -webkit-transform: translate(0px, 0px); }
	55%      { -webkit-transform: translate(0px, 0px) rotate(-2deg); }
}

#mulle-mullerich {
    z-index: 3;
    animation: mullerich 3s infinite ease-in-out;
}

@keyframes welcome-starburst {
    to {
        transform:rotate(-1turn)
    }
}
