/* layout */

.pageband:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.bandcontent {
  max-width: $max-width;
  min-width: $min-width;
  margin: 0 auto 0 auto;
  padding: 1em 2em;
  
}


/* main sections layout and formatting */

div#pagecontent-band {
  margin-top: 2em;
  margin-bottom: 2em;
}

section.content {
  @extend .bandcontent;

  h1 + p, p.byline + p {
    margin-top: 2em;
  }
  
  p.byline {
    margin-top: 0.3em;
    font-weight: bold;
  }

  h2 {
    padding-bottom: 4px;
    border-bottom: $line-style;
  }
  p:first-child {
    margin-top: 0;
  }
  li {
    font-size: 100%;
    list-style-type: disc;
    margin-left: 1.8em;
  }
  li:first-child {
    margin-top: .8em;
  }
  
  blockquote {
    :before {
      font-size: 2em;
      color: lighten($body-color, 20%);
      content: '\201C';
    }
    p {
      font-style: italic;
    }
  }
  
}

.narrow {
  p, ol, ul {
    width: 80ex;
  }
}



h2 + article > header > h3:first-child, h2 + h3, h2 + div > h3 {
  margin-top: 0;
}


.post {
  margin-top: 1.2em;
}

.postimg {
  float: left;
}

.postcontent {
  padding-left: 0.8175em;
  display: table;

  h3 {
    margin-top: 0;
  }
}



/* navigation */

#nav-band {
    background-color: $nav-bg;
}

nav {
  @extend .bandcontent;
  color: $nav-color;
  height: 62px; 
}

#site-nav {
    float: left;   
    padding-top: 16pt;

    a {
        font-family: $heading-font;
        font-weight: normal;
        font-size: 14pt;
        color: white;
        padding-right: 10pt;
    }
}

#mulle-logo-nav {
    width: $nav-logo-width;
    height: $nav-logo-height;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#mulle-people {
    float: right;
    padding-top: 8pt;
}

#lang-selector {
  float: right;
  padding-top: 10pt;
  padding-left: 6pt;

  img {
    border: 1px solid #036;
  }
}

img.avatar {
    border: 1px solid #036;
    height: 36px;
    width: 36px;
}


/* other page furniture */

#browserwarning {
  display: none;
}


#footer-band {
  background: $footer-bg;

  footer {
    @extend .bandcontent;

    p {
      font-size: 0.875em;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
