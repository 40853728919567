$midscreen-width: 820px !default;
$smallscreen-width: 705px !default;

$midscreen-scale: 0.75;
$smallscreen-scale: 0.50;


@media screen and (max-width: $midscreen-width) {

  #lang-selector {
    display: none;
  }

  #mulle-logo-nav {
    display: none;
  }

  .mulle-element-welcome {
    width: $welcome-logo-width * $midscreen-scale;
    height: $welcome-logo-height * $midscreen-scale;
  }

  #mulle-logo {
    width: $welcome-logo-width * $midscreen-scale;
    height: $welcome-logo-height * $midscreen-scale;
    margin-top: 20pt;
  }

  #mulle-title {
    margin-top: 12pt;
    font-size: $welcome-text-size * $midscreen-scale;
  
    .cap {
      font-size: $welcome-text-size * $midscreen-scale * $welcome-cap-scale;
    }
  }

}


@media screen and (max-width: $smallscreen-width) {

  div#navmenu-btn {
    display: block;
  }
  nav {
    padding: 0;
    font-size: 1.5em;
    color: #EEE;
  }

  .mulle-element-welcome {
    width: $welcome-logo-width * $smallscreen-scale;
    height: $welcome-logo-height * $smallscreen-scale;
  }

  #mulle-logo {
    width: $welcome-logo-width * $smallscreen-scale;
    height: $welcome-logo-height * $smallscreen-scale;
    margin-top: 20pt;
  }

  #mulle-title {
    margin-top: 12pt;
    font-size: $welcome-text-size * $smallscreen-scale;
  
    .cap {
      font-size: $welcome-text-size * $smallscreen-scale * $welcome-cap-scale;
    }
  }

  #mulle-tagline {
    margin-bottom: 20pt;
    font-family: $title-font;
    font-size: 9pt;
    text-align: center;
  }

  section.content, nav, #footer-band footer {
    min-width: 100px;
    padding: 0.5em;
  }

  #site-nav {
    padding-top: 0pt;

    a {
      font-size: 12pt;
      padding-right: 6pt;
    }
  }

  div.teaserbox {
    width: 100%;
  }

  .narrow p, .narrow ol, .narrow ul {
    width: 100%;
  }

}

