
#welcome-band {
    color: $title-color;
    /* background-color needs alpha! */
    background-color: rgba(85, 119, 153, 0.8);
    position: relative;
    overflow: hidden;
}

#welcome-band::before {
    --starburst-width: 150vw;
    content: "";
    position: absolute;
    opacity: 0.3;
    z-index: -2;
    top: calc(-1 * var(--starburst-width) / 2 + 40%);
    left: calc(-1 * var(--starburst-width) / 2 + 50%);
    width: var(--starburst-width);
    height: var(--starburst-width);
    animation: welcome-starburst 60s linear infinite;
    background: repeating-conic-gradient(#fff 0 9deg, #579 9deg 18deg);
}

#mulle-logo {
    width: $welcome-logo-width;
    height: $welcome-logo-height;
    position: relative;
    margin: 0 auto;
    margin-top: 40pt;
}

#mulle-title {
    margin-top: 24pt;
    font-family: $title-font;
    font-size: $welcome-text-size;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;

    .cap {
        font-size: $welcome-text-size * $welcome-cap-scale;
    }
}

#mulle-tagline {
    margin-bottom: 40pt;
    font-family: $title-font;
    font-size: 15pt;
    text-align: center;
}


#teaser {
	margin-top: 2em;
}

div.teaserbox {
	float: left;
	margin: 0 $boxspacing 2em 0;
	width: $max-width / 3 - $boxspacing - 1;
}

div.teaserbox-double {
	float: left;
	margin: 0 $boxspacing 2em 0;
	width: $max-width / 3 * 2 - $boxspacing - 1;
}


