
/* heading and paragraph spacing */

h1 {
	margin-bottom: 1em;
}

h2 {
	margin-top: 1.5em;
	margin-bottom: 0.4em;
}

h2:first-child {
	margin-top: 0em;
}

h3, h4 {
	margin-top: 1.2em;
	margin-bottom: 0.3em;
}

.with-info {
	margin-bottom: 0;
}

p, ul, ol {
	margin-top: 0;
	margin-bottom: 0.715em;
}
	
p.info {
	margin-top: 0;
	margin-bottom: 0.1em;
}

p.loading {
	font-style: italic;
}


/* heading and paragraph fonts */

.heading {
	font-family: $heading-font;
	font-weight: bold;
	color: $heading-color;
}

h1 {
	@extend .heading;
	font-size: 2em;
}

h2 {
	@extend .heading;
	font-size: 1.5em;
}

h3 {
	@extend .heading;
	font-family: $body-font;
	font-size: 1.2em;
}

h4 {
	@extend .heading;
	font-family: $body-font;
	font-size: 1.0em;
}

p, th, td, ol, ul, li {
	font-family: $body-font;
	font-size: 1em;
	line-height: 1.4375em;
	color: $body-color;
}   

p.info {
	font-size: 0.875em;
	font-weight: bold;
	color: $heading-color;
}

i, em {
	font-style: italic;
}

b, strong {
	font-weight: bold;
}


/* links */

a {
	color: $body-color;
	text-decoration: none;
}

p a, li a {
	color: $link-color;
}

a:hover {
	text-decoration: underline;
}


/* general formatting */

img {
	max-width: 100%;
}

pre {
  	margin: .3em 0;
  	padding: .5em .7em;
  	background-color: darken($body-bg, 5%);
  	border: $line-style;
	border-radius: 3px 3px 3px 3px;
 	overflow-x: auto;
}


/* specials */

.tag {
	border: 1px solid #AAA;
	background: #DDD;
	padding: 1px 3px 0px 2px;
	border-radius: 3px 3px 3px 3px;
}

pre {
	font-family: $mono-font;
	font-size: 0.75em; 
	line-height: 1.3em;
	color: #444;
}

