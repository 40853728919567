
/* custom fonts */

@font-face {
    font-family: "PT Serif";
    src: url(/assets/fonts/PTF55F.ttf) format('truetype');
    font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: "PT Serif";
    src: url(/assets/fonts/PTF75F.ttf) format('truetype');
    font-weight: bold;
	font-style: normal;
}

@font-face {
    font-family: "PT Sans";
    src: url(/assets/fonts/PTS55F.ttf) format('truetype');
    font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: "PT Sans";
    src: url(/assets/fonts/PTS56F.ttf) format('truetype');
    font-weight: normal;
	font-style: italic;
}

@font-face {
    font-family: "PT Sans";
    src: url(/assets/fonts/PTS75F.ttf) format('truetype');
    font-weight: bold;
	font-style: normal;
}

@font-face {
    font-family: "PT Sans";
    src: url(/assets/fonts/PTS76F.ttf) format('truetype');
    font-weight: bold;
	font-style: italic;
}
