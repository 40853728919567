$max-width: 960px !default;
$min-width: 400px !default;
$boxspacing: 60px !default;

$nav-bg: #79B;
$nav-color: white;

$title-bg: #579;
$title-color: white;

$body-bg: white;
$heading-color: #333;
$body-color: #444;
$link-color: #4183C4;
$line-color: #CCC;
$line-style:  1px solid $line-color;

$footer-bg: #CCC;

$title-font: Cochin, Garamond, Verdana,sans-serif !default;
$heading-font: "PT Serif", sans-serif !default;
$body-font: "PT Sans", sans-serif !default;
$mono-font: Menlo, Monaco, "Andale Mono", "lucida console", "Courier New", monospace !default;

$welcome-logo-width: 250px !default;
$welcome-logo-height: 250px !default;

$welcome-text-size: 44pt;
$welcome-cap-scale: 1.136;

$nav-logo-width: 60px !default;
$nav-logo-height: 60px !default;
